import React, { useState } from 'react'
import Connectors from './connectors.json';
import Layout from '../components/layout'
import Modal from '../components/modal'

const IndexPage = () => {

  const [selectedConnector, setSelectedConnector] = useState({
    connectorName: "",
    connectorLogo: "",
    connectorDescription: ""
  })

  const [open, setOpen] = useState(false)

  const addToLocal = (connector) => {
    var existingConnectors = JSON.parse(localStorage.getItem('appConnectors')) || [];

    existingConnectors.push(connector);

    localStorage.setItem('appConnectors', JSON.stringify(existingConnectors));
  }


  const activateConnectCard = (name, logo, description) => {
    setSelectedConnector({...selectedConnector, connectorName: name, connectorLogo: logo, connectorDescription: description});
    setOpen(!open);
  }
  
  return (
    <main>
      <Layout pageTitle="Select Connectors">
        <Modal selectedConnector={selectedConnector} open={open} setOpen={setOpen} addToLocal={addToLocal}/>
        <ul role="list" className="grid grid-cols-4 gap-x-4 gap-y-8 sm:grid-cols-6 sm:gap-x-6 lg:grid-cols-8 xl:gap-x-8">
        {Connectors.data.items.map((connector) => (
          <li key={connector.id} className="relative" onClick={() => activateConnectCard(connector.name, connector.icon_url, connector.description)}>
            <div className="group block cursor-pointer w-full flex justify-center rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
              <img src={connector.icon_url} alt={connector.name} className="h-40 pointer-events-none w-40 group-hover:opacity-75" />
              <button type="button" className="absolute inset-0 focus:outline-none">
                <span className="sr-only">View details for {connector.name}</span>
              </button>
            </div>
            <p className="mt-2 text-center block text-sm font-medium text-gray-900 truncate pointer-events-none">{connector.name}</p>
          </li>
        ))}
      </ul>
      </Layout>
    </main>
  )
}

export default IndexPage